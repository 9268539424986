import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Popper",
  "type": "Other",
  "icon": "mapLine"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Popper`}</strong>{` used to render a context menu component used for tooltips and dropdowns.`}</p>
    <hr></hr>
    <h1>{`Example`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true background=layout",
        "live": "true",
        "center": "true",
        "background": "layout"
      }}>{`() => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState("top");
  const handleClick = () => {
    setOpen(!open);
  };
  const handlePlacement = () => {
    if (placement === "top") {
      setPlacement("left");
    }
    if (placement === "left") {
      setPlacement("bottom");
    }
    if (placement === "bottom") {
      setPlacement("right");
    }
    if (placement === "right") {
      setPlacement("top");
    }
  };

  return (
    <div style={{ padding: "120px 0" }}>
      <Button ref={setAnchorEl} onClick={handleClick}>
        Toggle
      </Button>
      <Popper anchorEl={anchorEl} open={open} placement={placement}>
        Example of a Popper component
      </Popper>
      <Separator />
      <Button onClick={handlePlacement}>Placement {placement}</Button>
    </div>
  );
};
`}</code></pre>
    <h1>{`Props`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`open`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If true the popper will be visible`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`anchorEl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`HTMLElement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Which element should we anchor to`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`placement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"top"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"left"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"bottom"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"right"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"top"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The position of the popper relative to the anchor`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      